import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Link, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import BlokoTranslateGuard from 'bloko/blocks/translateGuard';

import MetroPoint from 'src/components/MetroPoint';
import { ARROW_DOWN, ARROW_UP, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

export const SORT_TYPES = {
    city: {
        BY_CITY: {
            value: 'BY_CITY_REVERSED',
            icon: ARROW_UP,
        },
        BY_CITY_REVERSED: {
            value: 'BY_CITY',
            icon: ARROW_DOWN,
        },
        default: 'BY_CITY',
    },
    address: {
        BY_ADDRESS: {
            value: 'BY_ADDRESS_REVERSED',
            icon: ARROW_UP,
        },
        BY_ADDRESS_REVERSED: {
            value: 'BY_ADDRESS',
            icon: ARROW_DOWN,
        },
        default: 'BY_ADDRESS',
    },
    manager: {
        BY_MANAGER: {
            value: 'BY_MANAGER_REVERSED',
            icon: ARROW_UP,
        },
        BY_MANAGER_REVERSED: {
            value: 'BY_MANAGER',
            icon: ARROW_DOWN,
        },
        default: 'BY_MANAGER',
    },
};

export const SELECT_SORT_TYPES = [
    {
        value: 'BY_CITY',
        key: 'byCity',
        type: 'city',
    },
    {
        value: 'BY_CITY_REVERSED',
        key: 'byCityReversed',
        type: 'city',
    },
    {
        value: 'BY_ADDRESS',
        key: 'byAddress',
        type: 'address',
    },
    {
        value: 'BY_ADDRESS_REVERSED',
        key: 'byAddressReversed',
        type: 'address',
    },
    {
        value: 'BY_MANAGER',
        key: 'byManager',
        type: 'manager',
    },
    {
        value: 'BY_MANAGER_REVERSED',
        key: 'byManagerReversed',
        type: 'manager',
    },
];

export const MetroAddresses = ({ metroStations }) => {
    if (!metroStations) {
        return null;
    }

    return metroStations.metro.map((metro, index) => {
        return (
            <Fragment key={index}>
                <MetroPoint name={metro.name} color={metro.line.color} id={metro.id} />
                <br />
            </Fragment>
        );
    });
};

export const OrderSwitch = ({ text, type, handleChangeSort, loadingComponent, order }) => {
    return (
        <>
            <Link
                appearance={loadingComponent === type ? LinkAppearance.Loading : LinkAppearance.Pseudo}
                kind={LinkKind.Tertiary}
                onClick={() => handleChangeSort(type)}
                data-sort-by={type}
            >
                {text}
            </Link>
            {NON_BREAKING_SPACE}
            <BlokoTranslateGuard useSpan>{SORT_TYPES[type][order] && SORT_TYPES[type][order].icon}</BlokoTranslateGuard>
        </>
    );
};

OrderSwitch.propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
    handleChangeSort: PropTypes.func,
    loadingComponent: PropTypes.string,
    order: PropTypes.string,
};

MetroAddresses.propTypes = {
    metroStations: PropTypes.object,
};

export const addressField = (street, building) => {
    if (street) {
        return building ? `${street}, ${building}` : street;
    }
    return building;
};

export const deleteAddress = (address) => {
    return (
        <span data-qa="vacancy-view-raw-address">
            <i className="icon-pin icon-pin_vacancy-address" />
            {address.metroStations &&
                address.metroStations.metro.map((metro, index) => {
                    return (
                        <Fragment key={index}>
                            <MetroPoint name={metro.name} color={metro.line.color} />
                            {', '}
                        </Fragment>
                    );
                })}
            {address.displayName}
        </span>
    );
};
