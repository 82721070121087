import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';
import CustomSelect, { CustomSelectOption } from 'bloko/blocks/customSelect';
import Gap from 'bloko/blocks/gap';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import Pagination from 'src/components/Pagination';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import EmployerAddressesFilter from 'src/pages/EmployerAddresses/components/EmployerAddressesFilter';
import EmployerAddressesItem from 'src/pages/EmployerAddresses/components/EmployerAddressesItem';
import EmployerAddressesModal from 'src/pages/EmployerAddresses/components/EmployerAddressesModal';
import { SORT_TYPES, SELECT_SORT_TYPES, OrderSwitch } from 'src/pages/EmployerAddresses/components/Utils';
import fetchAddressesList from 'src/pages/EmployerAddresses/components/fetchAddressesList';

const EmployerAddressesComponent = ({ trls, addresses, filters, loadingComponent, paging }) => {
    const dispatch = useDispatch();
    const query = useSelector(({ router }) => router.location.query);
    const isXs = useBreakpoint(Breakpoint.M) === Breakpoint.XS;

    const handleChangeSort = (type) => {
        let order = SORT_TYPES[type].default;

        if (filters.order in SORT_TYPES[type]) {
            order = SORT_TYPES[type][filters.order].value;
        }

        dispatch(
            fetchAddressesList({
                params: { filters: { ...query, order }, loadingComponent: type },
            })
        );
    };

    const renderGtXsContent = () => (
        <table className="prosper-table">
            <tbody>
                <tr>
                    <th className="prosper-table__head">
                        <OrderSwitch
                            text={trls[EmployerAddressesComponent.trls.city]}
                            type="city"
                            handleChangeSort={handleChangeSort}
                            loadingComponent={loadingComponent}
                            order={filters.order}
                        />
                    </th>
                    <th className="prosper-table__head">
                        <OrderSwitch
                            text={trls[EmployerAddressesComponent.trls.address]}
                            type="address"
                            handleChangeSort={handleChangeSort}
                            loadingComponent={loadingComponent}
                            order={filters.order}
                        />
                    </th>
                    <th className="prosper-table__head">{trls[EmployerAddressesComponent.trls.metro]}</th>
                    <th className="prosper-table__head">
                        <OrderSwitch
                            text={trls[EmployerAddressesComponent.trls.manager]}
                            type="manager"
                            handleChangeSort={handleChangeSort}
                            loadingComponent={loadingComponent}
                            order={filters.order}
                        />
                    </th>
                    <th className="prosper-table__head prosper-table__head_compact" />
                </tr>
            </tbody>
            <tbody>
                {addresses.map((address) => (
                    <EmployerAddressesItem key={address.id} {...address} />
                ))}
            </tbody>
        </table>
    );

    const renderXsContent = () => (
        <>
            <CustomSelect
                value={filters.order}
                onChange={(val) => {
                    const type = SELECT_SORT_TYPES.find((item) => item.value === val).type;

                    handleChangeSort(type);
                }}
            >
                {SELECT_SORT_TYPES.map((item) => (
                    <CustomSelectOption key={item.key} value={item.value}>
                        <div>{trls[EmployerAddressesComponent.trls[item.key]]}</div>
                    </CustomSelectOption>
                ))}
            </CustomSelect>
            <VSpacing base={2} />
            <div className="address-items-container">
                {addresses.map((address) => (
                    <EmployerAddressesItem key={address.id} {...address} isXsItem />
                ))}
            </div>
        </>
    );

    return (
        <Column xs="4" s="8" m="12" l="16">
            <div className="address-actions-container">
                <EmployerAddressesModal type="add" />
                <EmployerAddressesFilter isXs={isXs} />
            </div>
            <VSpacing base={4} />
            {isXs && renderXsContent()}
            {!isXs && renderGtXsContent()}
            <div className="address-pagination">
                <Gap top>
                    <Pagination {...paging} />
                </Gap>
            </div>
        </Column>
    );
};

EmployerAddressesComponent.propTypes = {
    trls: PropTypes.object,
    addresses: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    loadingComponent: PropTypes.string,
    paging: PropTypes.object,
};

EmployerAddressesComponent.trls = {
    city: 'employer.address.city',
    address: 'vacancy.address',
    metro: 'commons.metro',
    manager: 'employerManager.type.MANAGER',
    byCityReversed: 'employer.address.sort.byCityReversed',
    byCity: 'employer.address.sort.byCity',
    byAddressReversed: 'employer.address.sort.byAddressReversed',
    byAddress: 'employer.address.sort.byAddress',
    byManagerReversed: 'employer.address.sort.byManagerReversed',
    byManager: 'employer.address.sort.byManager',
};

export default connect(({ employerAddressesPage: { addresses, filters, loadingComponent, paging } }) => ({
    addresses,
    filters,
    loadingComponent,
    paging,
}))(translation(EmployerAddressesComponent));
