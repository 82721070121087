import PropTypes from 'prop-types';

import PageLayout, { HeaderLayout } from 'src/app/layouts/PageLayout';
import EmployerCompanyMenu from 'src/components/EmployerCompanyMenu';
import translation from 'src/components/translation';

import EmployerAddressesComponent from 'src/pages/EmployerAddresses/components/EmployerAddressesComponent';

const EmployerAddresses = ({ trls }) => (
    <PageLayout title={trls[EmployerAddresses.trls.title]} layout={HeaderLayout}>
        <EmployerCompanyMenu />
        <EmployerAddressesComponent />
    </PageLayout>
);

EmployerAddresses.trls = {
    title: 'employer.company.title',
};

EmployerAddresses.propTypes = {
    trls: PropTypes.object,
};

export default translation(EmployerAddresses);
