import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import Tabs, { Tab } from 'bloko/blocks/tabs';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    employerManagers: 'mainmenu.menuitem.employerManagers',
    addressesEdit: 'mainmenu.menuitem.addressesEdit',
    employerDocuments: 'mainmenu.menuitem.employerDocuments',
    companyStructure: 'mainmenu.menuitem.companyStructure',
    employerReviewsSettings: 'mainmenu.menuitem.employerReviewsSettings',
    employerAutomationInfo: 'mainmenu.menuitem.employerAutomationInfo',
};

const EmployerCompanyMenu: TranslatedComponent = ({ trls }) => {
    const menu = useSelector((state) => state.employerCompanyMenu);
    return (
        <Column xs="4" s="8" m="12" l="16">
            <Gap top bottom>
                <Tabs>
                    {menu.map(({ url, active, name }) => (
                        <Tab id={url} disabled={active} to={url} active={active} key={url} Element={Link}>
                            {trls[TrlKeys[name as keyof typeof TrlKeys]]}
                        </Tab>
                    ))}
                </Tabs>
            </Gap>
        </Column>
    );
};

export default translation(EmployerCompanyMenu);
