import { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { FormSpacer } from 'bloko/blocks/form';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import EmployerAddressesDelete from 'src/pages/EmployerAddresses/components/EmployerAddressesDelete';
import EmployerAddressesModal from 'src/pages/EmployerAddresses/components/EmployerAddressesModal';
import { MetroAddresses, addressField } from 'src/pages/EmployerAddresses/components/Utils';

const EmployerAddressesItem = ({
    city,
    street,
    building,
    metroStations,
    manager,
    id,
    description,
    rawAddress,
    isNewAddress,
    canEdit,
    isXsItem,
}) => {
    const [isNewRow, setIsNewRow] = useState(isNewAddress);

    useEffect(() => {
        if (isNewAddress) {
            requestAnimationFrame(() => {
                setIsNewRow(false);
            });
        }
    }, [isNewAddress]);

    const isDeprecatedAddress = rawAddress && (!city || !street || !building);

    if (isXsItem) {
        return (
            <div>
                <VSpacing base={4} />
                <div className="address-item-header">
                    <Text strong size={TextSize.Large}>
                        {!isDeprecatedAddress ? addressField(street, building) : rawAddress}
                    </Text>
                    {(canEdit || isNewAddress) && (
                        <div className="address-item-action-icons">
                            <EmployerAddressesModal type="edit" addressId={id} />
                            <FormSpacer>
                                <EmployerAddressesDelete addressId={id} />
                            </FormSpacer>
                        </div>
                    )}
                </div>
                <VSpacing base={1} />
                {description && (
                    <>
                        <Text size={TextSize.Medium} importance={TextImportance.Tertiary}>
                            {description}
                        </Text>
                        <VSpacing base={1} />
                    </>
                )}
                <VSpacing base={3} />
                <Text size={TextSize.Medium}>{city}</Text>
                {metroStations && (
                    <>
                        <VSpacing base={2} />
                        <div className="address-metro-container">
                            <MetroAddresses metroStations={metroStations} />
                        </div>
                    </>
                )}
                <VSpacing base={4} />
                <Text size={TextSize.Medium} importance={TextImportance.Tertiary}>
                    {manager.fullname}
                </Text>
                <VSpacing base={4} />
            </div>
        );
    }

    return (
        <tr
            className={classnames('prosper-table-row prosper-table-row_opacity-transition', {
                'prosper-table-row_zero-opacity': isNewRow,
            })}
            data-manager={manager.fullname}
            data-address-id={id}
            data-city={city}
            data-address={addressField(street, building)}
        >
            <td className="prosper-table__cell" data-qa="address-item-city">
                {!isDeprecatedAddress ? city : rawAddress}
            </td>
            <td className="prosper-table__cell">
                <span data-qa="address-item-text">{addressField(street, building)}</span>
                {description && (
                    <div className="address-description" data-qa="address-item-description">
                        <Text importance={TextImportance.Tertiary}>{description}</Text>
                    </div>
                )}
            </td>
            <td className="prosper-table__cell" data-qa="address-item-metro">
                <MetroAddresses metroStations={metroStations} />
            </td>
            <td className="prosper-table__cell" data-qa="address-item-manager">
                {manager.fullname}
            </td>
            <td className="prosper-table__cell">
                {(canEdit || isNewAddress) && (
                    <div className="icon-group">
                        <EmployerAddressesModal type="edit" addressId={id} />
                        <FormSpacer>
                            <EmployerAddressesDelete addressId={id} />
                        </FormSpacer>
                    </div>
                )}
            </td>
        </tr>
    );
};

EmployerAddressesItem.propTypes = {
    city: PropTypes.string,
    street: PropTypes.string,
    building: PropTypes.string,
    metroStations: PropTypes.object,
    manager: PropTypes.object,
    id: PropTypes.number.isRequired,
    description: PropTypes.string,
    rawAddress: PropTypes.string,
    isNewAddress: PropTypes.bool,
    canEdit: PropTypes.bool,
    isXsItem: PropTypes.bool,
};

export default EmployerAddressesItem;
